
import { defineComponent } from 'vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import UiSearch from '@/components/form/UiSearch.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import moment from 'moment-timezone';
import HistoryService from '@/services/HistoryService';

export default defineComponent({
  name: 'GlobalHistory',
  components: {
    PreLoader,
    UiSearch,
    Pagination,
  },
  data: () => ({
    isLoaded: false,
    records: [],
    recordsTotal: 0,
    recordPage: 1,
    recordPages: 0,
    searchInput: '',
    searchTimeoutId: 0,
    timezone: moment.tz.guess(),
  }),
  computed: {
    currentItems(): number {
      const total = 10 * this.recordPage;

      return total >= this.recordsTotal ? this.recordsTotal : total;
    },
  },
  watch: {
    searchInput: {
      immediate: true,
      handler() {
        window.clearTimeout(this.searchTimeoutId);

        this.searchTimeoutId = window.setTimeout(async () => {
          this.recordPage = 1;
          this.activityLogList();
        }, 300);
      },
    },
  },
  methods: {
    formatDate(date: string) {
      return moment(date).tz(this.timezone).format('YYYY-MM-DD');
    },
    async activityLogList() {
      const params = {
        search: this.searchInput,
        source: 'Inventory',
        timezone: this.timezone,
        page: this.recordPage,
        take: 10,
      };

      const response = await HistoryService.activityLogList(params);

      this.records = response.data;
      this.recordsTotal = response.total;
      this.recordPages = response.last_page;
      this.isLoaded = true;
    },
  },
});
