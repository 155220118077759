import http from '../utils/http-client';

class HistoryService {
  static async activityLogList(params?: any): Promise<any> {
    const response = await http.get('/api/history/activitylog/list', { params });

    return response;
  }
}

export default HistoryService;
